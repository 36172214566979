// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".error_message__root___qCR92{position:absolute;bottom:0px;right:5px;color:#e01c1c;transform:translate(0, 150%)}", "",{"version":3,"sources":["webpack://./src/scripts/form/ErrorMessage/error_message.scss","webpack://./src/styles/global/_colors.scss"],"names":[],"mappings":"AAEA,6BACE,iBAAA,CACA,UAAA,CACA,SAAA,CACA,aCYS,CDXT,4BAAA","sourcesContent":["@import \"@styles/global/_parts.scss\";\n\n.root {\n  position: absolute;\n  bottom: 0px;\n  right: 5px;\n  color: $errorRed;\n  transform: translate(0, 150%);\n}\n","//theme colors\n$primary_brown: #a2662f;\n$primary_black: #000;\n$primary_white: #fff;\n$secondary_green: #395437;\n$secondary_grey: #d1cbbc;\n$secondary_orange: #f8992e;\n$secondary_gold: #ffc709;\n$secondary_yellow: #fee000;\n\n//other found colors\n$black_100: #e8e5db;\n$black_300: #6b6b6b;\n$secondary_beige: #f3f1ed;\n$secondary_tan: #feebd5;\n\n$white: #fff;\n$black: $primary_black;\n$errorRed: #e01c1c;\n$blue: #4d90fe;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "error_message__root___qCR92"
};
export default ___CSS_LOADER_EXPORT___;
