import messages from "./en.messages.json";

export default {
  messages,
  id: "english",
  code: "en",
  label: "English",
  path: "/en",
  dir: "ltr",
};
