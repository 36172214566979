import { Link, Text } from "@scripts/widgets";
import styles from "./nav_mobile.scss";
import { _classes } from "@scripts/utilities/helpers";
import useAppState from "@scripts/providers/AppProvider";

const cl = _classes(styles);

NavMobile.propTypes = {
  items: PropTypes.array,
};

export default function NavMobile({ items = [] }) {
  const { childrenByPath, setMenuOpen, menuOpen } = useAppState();

  const renderChildren = (childItems) => {
    return childItems.map((item) => {
      return (
        <li
          key={item.id}
          className={cl("item")}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <Link path={item.displayPath}>
            <Text
              text={item.linktitle}
              font="secondary"
              variant="mobileNavChild"
              className={cl("text")}
            />
          </Link>
        </li>
      );
    });
  };

  return (
    <nav className={cl("root")}>
      {items?.map((item) => {
        const itemWithChildrenForNav = childrenByPath(item.path)?.filter(
          (item) =>
            !item.template.match(/room|venue|package_detail|gallery_category/)
        );
        const activePath = window.location.pathname.match(item.displayPath);

        return (
          <li
            key={item.id}
            className={cl(["item", activePath && "active"])}
            onClick={() => setMenuOpen(!menuOpen)}
          >
            <Link path={item.displayPath}>
              <Text
                text={item.linktitle}
                font="secondary"
                className={cl("text")}
                variant="mobileNavMain"
              />
            </Link>
            {itemWithChildrenForNav.length ? (
              <ul className={cl(["child_menu", activePath && "active"])}>
                {renderChildren(itemWithChildrenForNav)}
              </ul>
            ) : (
              ""
            )}
          </li>
        );
      })}
    </nav>
  );
}
