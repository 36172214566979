import styles from "./horizontal_content_block.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text, Image, Wysiwyg, CTA } from "@scripts/widgets";
import { flower } from "@scripts/widgets/icons";
import useAppState from "@scripts/providers/AppProvider";

HorizontalContentBlock.propTypes = {
  orientation: PropTypes.oneOf(["content_first", "image_first"]),
  amenities: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  html: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  cta: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  decoration: PropTypes.bool,
};

export default function HorizontalContentBlock({
  amenities = "",
  orientation = "content_first",
  title = "",
  description = "",
  image,
  cta,
  subtitle = "",
  html = "",
  decoration = false,
}) {
  const { accessible } = useAppState();
  const cl = accessible ? () => null : _classes(styles);

  return (
    <section className={cl(["root", orientation])}>
      <div className={cl(["container", orientation])}>
        <div className={cl(["content", orientation])}>
          <div className={cl(["inner", orientation])}>
            {decoration && (
              <div className={cl(["decoration", orientation])}>{flower}</div>
            )}
            <div className={cl("details")}>
              {subtitle && (
                <Text text={subtitle} component="h3" font="secondary" />
              )}
              <Text
                text={title}
                variant="h3"
                component="h2"
                className={cl("title")}
              />
              {html && <Wysiwyg html={html} />}

              {description && <Text text={description} component="p" />}
              {amenities && (
                <div
                  className={cl("content-wrap")}
                  dangerouslySetInnerHTML={{ __html: amenities }}
                />
              )}
              {cta?.link && cta?.text && (
                <CTA
                  link={cta?.link}
                  text={cta?.text}
                  variant="outline_transparent"
                />
              )}
            </div>
          </div>
          <div className={cl("content__image")}>
            <div className={cl(["image", orientation])}>
              <Image src={image?.src} alt={image?.alt} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
