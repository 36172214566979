import styles from "./increment_field.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text } from "@scripts/widgets";
import useBookingWidgetContext from "../../bookingWidget/BookingWidgetProvider";

const cl = _classes(styles);

IncrementField.propTypes = {
  name: PropTypes.string,
  max: PropTypes.number,
  childrenSubtitle: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  hideControls: PropTypes.bool,
  defaultValue: PropTypes.number,
  alt: PropTypes.bool,
};

export default function IncrementField({
  name,
  min = 1,
  max = 10,
  childrenSubtitle = "",
  label,
  hideControls,
  alt,
}) {
  const { formState, setValue } = useBookingWidgetContext();

  const value = formState[name];

  return (
    <div className={cl(["root", alt ? "alt" : ""])}>
      <div className={cl("field")}>
        <Text
          component="label"
          variant="body3"
          font="secondary"
          text={label || `booking.${name}`}
          className={cl("label")}
        />
        <div className={cl("field__inner")}>
          <button
            type="button"
            onClick={() => {
              const newValue = Number(value) - 1;
              if (newValue >= min) {
                setValue(name, newValue);
              }
            }}
            className={cl(["button", hideControls ? "disabled" : ""])}
          >
            <Text text={"-"} variant="h5" weight="bold" />
          </button>
          <Text
            text={value?.toString()}
            variant="h5"
            weight="bold"
            className={cl("value")}
          />

          <button
            type="button"
            onClick={() => {
              const newValue = (Number(value) || 0) + 1;
              if (newValue <= max) {
                setValue(name, newValue);
              }
            }}
            className={cl(["button", hideControls ? "disabled" : ""])}
          >
            <Text text={"+"} variant="h5" weight="bold" />
          </button>
        </div>
        {childrenSubtitle && (
          <Text
            component="span"
            variant="body4"
            font="secondary"
            text={`booking.${childrenSubtitle}`}
            className={cl("sublabel")}
          />
        )}
      </div>
    </div>
  );
}
