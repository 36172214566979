import messages from "./he.messages.json";

export default {
  messages,
  id: "hebrew",
  code: "he",
  label: "Hebrew",
  path: "/he",
  dir: "rtl",
};
