import styles from "./footer.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Container, Link, Text, CTA } from "@scripts/widgets";
import { facebook, instagram } from "@scripts/widgets/icons";
import { FACEBOOK_URL, INSTAGRAM_URL } from "@scripts/config/appConfig";
import useAppState from "@scripts/providers/AppProvider";
import { useLocalization } from "@scripts/localization";
import { useMemo } from "react";
import { LOCATIONS, LOCATIONS_HE } from "@scripts/config/appConfig";
import { FormProvider, StandardInput } from "@scripts/form";

Footer.propTypes = {};

export default function Footer() {
  const { childrenByPath, accessible } = useAppState();
  const { currentLocale, rtlClass } = useLocalization();

  const cl = accessible ? () => null : _classes(styles);
  const { byTemplate } = useAppState();

  const getNavItems = () =>
    childrenByPath(currentLocale.path)?.filter((item) =>
      item.template.match(/about|contact|legal/)
    );

  const items = useMemo(getNavItems, [currentLocale]);

  const address = byTemplate("footer").find(
    (p) => p.h5.toLowerCase() === currentLocale.code
  );
  const localeLocations = currentLocale.code == "he" ? LOCATIONS_HE : LOCATIONS;

  const renderLocationsAndLinks = () => (
    <ul className={cl("locations")}>
      <li className={cl(["location", rtlClass])}>
        <Text text={address.h2} className={cl("text")} font="secondary" />
        <Text text={address.address1} className={cl("text")} />
        {/* <Text text={address.address2} className={cl("text")} /> */}
        <div>
          <Text
            text={`${address.city} ${address.postal_code}`}
            className={cl("text")}
          />
        </div>
        <div>
          <Text text={address.h3} className={cl("text")} />
        </div>
        <Link path={`tel:${address.phone}`}>
          <Text text={address.phone} className={cl("text")} />
        </Link>
        <Link path={`mailto:${address.h4}`}>
          <Text text={address.h4} className={cl("text")} />
        </Link>
      </li>
      <nav className={cl("links")}>
        {items.map((item) => (
          <li key={item.id} className={cl(["link_item", rtlClass])}>
            <Link path={item.displayPath} className={cl("link")}>
              <Text
                text={item.linktitle}
                className={cl("text")}
                font="secondary"
              />
            </Link>
          </li>
        ))}
      </nav>
    </ul>
  );

  const renderSocial = () => {
    return (
      <div className={cl(["social", rtlClass])}>
        {FACEBOOK_URL !== null && (
          <Link path={FACEBOOK_URL} className={cl(["icon", rtlClass])}>
            {accessible ? "FACEBOOK" : facebook}
          </Link>
        )}
        <Link path={INSTAGRAM_URL} className={cl(["icon", rtlClass])}>
          {accessible ? "INSTAGRAM" : instagram}
        </Link>
      </div>
    );
  };

  const renderForm = () => (
    <div className={cl("form")}>
      <Text
        text="footer.subscribeToNewsletter"
        variant="body1"
        weight="bold"
        font="secondary"
        component="p"
        className={cl("form_title")}
      />
      <FormProvider
        onSubmit={"newsletter"}
        thankYouClassname={cl("form__thank_you")}
      >
        <div className={cl(["row", rtlClass])}>
          <StandardInput
            name="name"
            label="form.name.label"
            rules={{ required: "form.name.required" }}
            variant="light"
          />
        </div>
        <div className={cl(["row", rtlClass])}>
          <StandardInput
            name="email"
            label="form.email.label"
            rules={{ required: "form.email.required" }}
            variant="light"
          />
        </div>
        <div className={cl(["row", rtlClass])}>
          <CTA submit={true} text="common.submit" size="small" />
        </div>
      </FormProvider>
    </div>
  );

  return (
    <footer className={cl(["root"])}>
      <Container className={cl(["container", rtlClass])}>
        <div className={cl("left")}>
          {renderLocationsAndLinks()}
          {renderSocial()}
        </div>
        <div className={cl(["right", rtlClass])}>{renderForm()}</div>
      </Container>
    </footer>
  );
}
