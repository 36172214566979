import { Link, Text } from "@scripts/widgets";
import styles from "./nav.scss";
import { _classes } from "@scripts/utilities/helpers";
import useAppState from "@scripts/providers/AppProvider";
import { useState } from "react";

const cl = _classes(styles);

Nav.propTypes = {
  items: PropTypes.array,
};

export default function Nav({ items = [] }) {
  const { childrenByPath, accessible } = useAppState();
  const [activeMenu, setActiveMenu] = useState(null);

  const renderChildren = (item, children) => {
    const visible = item.id === activeMenu;
    const activePath = window.location.pathname.match(item.path);
    return (
      <ul
        className={cl([
          "child_menu",
          visible && "visible",
          activePath && "active",
        ])}
      >
        {children.map((item) => (
          <li key={item.id} className={cl("item")}>
            <Link path={item.displayPath}>
              <Text
                text={item.linktitle}
                font="secondary"
                variant="body2"
                className={cl("text")}
              />
            </Link>
          </li>
        ))}
      </ul>
    );
  };

  return (
    <nav className={cl(["root", accessible && "accessible"])}>
      {items?.map((item) => {
        const activePath = window.location.pathname.match(item.displayPath);
        const children = childrenByPath(item.path)?.filter(
          (item) =>
            !item.template.match(/room|venue|package_detail|gallery_category/)
        );
        return (
          <li
            key={item.id}
            className={cl(["item", activePath && "active"])}
            onMouseOver={() => setActiveMenu(item.id)}
            onMouseOut={() => setActiveMenu(null)}
            onFocus={() => setActiveMenu(item.id)}
            onBlur={() => setActiveMenu(null)}
          >
            <Link path={item.displayPath}>
              <Text
                text={item.linktitle}
                font="secondary"
                className={cl("text")}
              />
            </Link>
            {children && children.length
              ? renderChildren(item, children)
              : null}
          </li>
        );
      })}
    </nav>
  );
}
