import styles from "./rooms_card_list.scss";
import { _classes } from "@scripts/utilities/helpers";
import RoomsCard from "../RoomsCard/RoomsCard";

const cl = _classes(styles);

RoomsCardList.propTypes = {
  type: PropTypes.oneOf(["deluxe", "suites"]),
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      images: PropTypes.arrayOf(
        PropTypes.shape({
          src: PropTypes.string,
          alt: PropTypes.string,
        })
      ),
      cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
      }),
      setIsModalOpen: PropTypes.func,
    })
  ),
};

export default function RoomsCardList({ cards = [], type }) {
  return (
    <section className={cl(["root", type])}>
      <div className={cl(["container", type])}>
        {cards?.map((props, index) => (
          <RoomsCard {...props} key={index} type={type} />
        ))}
      </div>
    </section>
  );
}
