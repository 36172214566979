import ReactSelect from "react-select";
import { _classes } from "@scripts/utilities/helpers";
import styles from "./select.scss";

const cl = _classes(styles);

Select.propTypes = {
  items: PropTypes.array,
  onSelect: PropTypes.func,
  defaultValue: PropTypes.any,
  className: PropTypes.string,
};

export default function Select({
  items = [],
  onSelect,
  defaultValue,
  className = "",
}) {
  return (
    <ReactSelect
      defaultValue={items?.find((item) => item.value === defaultValue)}
      onChange={onSelect}
      options={items}
      classNamePrefix={"select"}
      className={cl(["root", className])}
      isSearchable={false}
    />
  );
}
