import styles from "./booking_widget_dates.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text } from "@scripts/widgets";
import { DayPickerUncontrolled } from "@scripts/form";
import AnimateHeight from "react-animate-height";
import React, { useState } from "react";
import useOnEscape from "@scripts/hooks/useOnEscape";
import dayjs from "dayjs";
import useBookingWidgetContext from "../../bookingWidget/BookingWidgetProvider";

const cl = _classes(styles);

BookingWidgetDates.propTypes = {
  numberOfMonths: PropTypes.number,
  mobile: PropTypes.boolean,
};

export default function BookingWidgetDates({ numberOfMonths, mobile }) {
  const { formState, setFormState } = useBookingWidgetContext();
  const [datesOpen, toggleDates] = useState(false);

  const datesValue = { from: formState["from"], to: formState["to"] };
  const close = () => toggleDates(false);
  useOnEscape(close);
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const disabledDays = [new Date(datesValue.from), { before: today }];

  return (
    <div className={cl(["root", mobile ? "mobile" : ""])}>
      <Text
        component="label"
        variant="body3"
        font="secondary"
        text={"booking.dates"}
        className={cl("label")}
      />

      <div className={cl("buttons")}>
        <button
          onClick={() => toggleDates(!datesOpen)}
          className={cl("button")}
          type="button"
        >
          <Text
            weight="bold"
            text={
              datesValue
                ? `${dayjs(datesValue.from).format("MM/DD/YY")}`
                : "booking.checkin"
            }
          />
        </button>
        <Text text=" - " className={cl("separator")} weight="bold" />
        <button
          onClick={() => toggleDates(!datesOpen)}
          className={cl("button")}
          type="button"
        >
          <Text
            weight="bold"
            text={
              datesValue && datesValue?.to
                ? `${dayjs(datesValue.to).format("MM/DD/YY")}`
                : "booking.checkout"
            }
          />
        </button>
      </div>

      <div className={cl("daypicker_wrapper")}>
        <AnimateHeight height={datesOpen ? "auto" : 0}>
          <DayPickerUncontrolled
            value={datesValue}
            name="dates"
            mode="range"
            disabledDays={disabledDays}
            numberOfMonths={numberOfMonths}
            onSelect={(range) => {
              if (datesValue?.from && datesValue?.to) {
                return null;
              }

              setFormState({ ...formState, ...range });

              if (range.from && range.to) {
                toggleDates();
              }
            }}
            onDayClick={(day) => {
              if (datesValue?.from && datesValue?.to) {
                const range = { from: day, to: null };
                setFormState({ ...formState, ...range });
              }

              if (datesValue.from && datesValue.to) {
                toggleDates();
              }
            }}
          />
        </AnimateHeight>
      </div>
    </div>
  );
}
