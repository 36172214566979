import styles from "./card.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text, CTA, Image } from "@scripts/widgets";

const cl = _classes(styles);

Card.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  cta: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  imageClassName: PropTypes.string,
};

export default function Card({
  title,
  image,
  description,
  cta,
  imageClassName = "",
}) {
  return (
    <article className={cl("root")}>
      <div className={cl(["image", imageClassName])}>
        <Image src={image.src} alt={image.alt} />
      </div>
      <div className={cl(["details"])}>
        <Text
          text={title}
          variant="h4"
          component="h3"
          className={cl("title")}
        />
        <Text text={description} component="p" className={cl("description")} />
        {cta && (
          <CTA
            link={cta?.link}
            text={cta?.text}
            variant="secondary"
            className={cl("cta")}
          />
        )}
      </div>
    </article>
  );
}
