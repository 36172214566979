import { lazy } from "react-imported-component";
const importFactory = (importFunction) => lazy(importFunction);
const requireViews = require.context(
  "views",
  true,
  /^(.*\.(js$))[^.]*$/im,
  "lazy"
);
const allpaths = requireViews.keys();
const viewsPaths = {};

allpaths.forEach((path) => {
  const splitPath = path.replace(/\.\//, "").replace(/\.js/, "").split("/");
  const folder = splitPath.slice(-2)[0];
  const name = splitPath.slice(-1)[0];

  viewsPaths[name.toLowerCase().replace(/_/g, "")] = importFactory(() =>
    import(/* webpackChunkName: "[request]" */ `../views/${folder}/${name}`)
  );
});

export default viewsPaths;
