import "@babel/polyfill";
import "./styles/main.scss";
import "./markup/index.pug";
import "./scripts/utilities/ProxyPolyfill";
import "focus-visible";

import App from "./scripts/App.js";
import API from "./scripts/utilities/API";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { AppProvider, ViewportProvider } from "./scripts/providers";
import { LocalizationProvider } from "./scripts/localization/LocalizationProvider";

async function fetch() {
  try {
    const api = new API({ base: API_PATH });
    const timeStamp = Date.now();

    const [{ data: content }, { data: slides }] = await Promise.all([
      api.get(`content?cacheBuster=${timeStamp}`),
      api.get(`slides?cacheBuster=${timeStamp}`),
    ]);

    const data = Object.keys(content).reduce((sum, id) => {
      const page = content[id];

      sum[id] = {
        ...page,
        ...slides[id],
        displayPath: page.path.replace(/^\/en/, page.path == "/en" ? "/" : ""),
        localeCode: page.path.match(/^\/(\w+)/)
          ? page.path.match(/^\/(\w+)/)[1]
          : null,
      };
      return sum;
    }, {});

    return data;
  } catch (e) {
    console.error("Could not fetch app data: ", e);
    return { error: e };
  }
}

function render(data) {
  if (data.error) {
    ReactDOM.render(
      <div>
        <h1>There was a problem loading the site.</h1>
      </div>,
      document.querySelector(".wrapper")
    );
  } else {
    ReactDOM.render(
      <Router>
        <Route
          render={(props) => (
            <AppProvider content={data} {...props}>
              <LocalizationProvider>
                <ViewportProvider>
                  <App {...props} />
                </ViewportProvider>
              </LocalizationProvider>
            </AppProvider>
          )}
        />
      </Router>,
      document.querySelector(".wrapper")
    );
  }
}

fetch().then(render);
