import styles from "./rooms_card.scss";
import { _classes, getRoomCode } from "@scripts/utilities/helpers";
import { Text, Link, Carousel, Wysiwyg } from "@scripts/widgets";
import { Accordion } from "@scripts/partials";
import useViewportContext from "../../../providers/ViewportProvider";
import { useLocalization } from "../../../localization";

const cl = _classes(styles);

RoomsCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  html: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      alt: PropTypes.string,
    })
  ),
  cta: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  link: PropTypes.string,
  type: PropTypes.oneOf(["deluxe", "suites"]),
  setIsModalOpen: PropTypes.func,
};

export default function RoomsCard({
  title = "",
  images = [],
  cta,
  subtitle = "",
  link,
  description,
  type = "deluxe",
  setIsModalOpen,
}) {
  const { viewport } = useViewportContext();
  const { rtlClass } = useLocalization();

  const isMobile = viewport.is("mobile");
  const roomCode = getRoomCode(title);

  return (
    <article className={cl(["root", type])}>
      <div className={cl(["details", type, rtlClass])}>
        <Text text={subtitle} component="h3" font="secondary" />
        <Accordion
          defaultExpanded={!isMobile}
          disabled={!isMobile}
          titleComponent={
            <Text
              text={title}
              variant="h3"
              component="h2"
              className={cl(["title", rtlClass])}
            />
          }
        >
          <Wysiwyg html={description} className={cl("wysiwyg")} />
          <Link path={link} onClick={setIsModalOpen}>
            <Text
              text={"rooms.seeAllAmenities"}
              component="h3"
              font="secondary"
              className={cl("link")}
            />
          </Link>
        </Accordion>
      </div>
      <div className={cl(["images", type])}>
        <Carousel slides={images} settings={{ dots: false }} />
      </div>
    </article>
  );
}
