import Nav from "../../Nav/Nav";
import NavMobile from "../../NavMobile/NavMobile";
import { useLocalization } from "@scripts/localization";
import styles from "./header.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Container, Link, Modal } from "@scripts/widgets";
import { logo, facebook, instagram } from "@scripts/widgets/icons";
import useOnScroll from "@scripts/hooks/useOnScroll";
import { useState, useMemo } from "react";
import Select from "@scripts/widgets/Select/Select";
import { FACEBOOK_URL, INSTAGRAM_URL } from "@scripts/config/appConfig";
import useViewportContext from "@scripts/providers/ViewportProvider";
import { hamburger } from "@scripts/widgets/icons";
import useAppState from "@scripts/providers/AppProvider";
import ScriptBookingWidget from "../../scriptBookingWidget/scriptBookingWidget";

const cl = _classes(styles);

Header.propTypes = {};

export default function Header() {
  const { mediumDown, mediumUp } = useViewportContext();
  const { changeLocale, locales, currentLocale, homePath, rtlClass } =
    useLocalization();

  const { childrenByPath, accessibleClass, accessible, menuOpen, setMenuOpen } =
    useAppState();

  const getNavItems = () =>
    childrenByPath(currentLocale.path)?.filter((item) => item.showinnav);

  const items = useMemo(getNavItems, [currentLocale]);
  const [scrolled, setScrolled] = useState(false);
  const [bookingOpen, setBookingOpen] = useState(false);

  useOnScroll(() => {
    setScrolled(window.scrollY > 200);
    if (window.scrollY > 200) {
      setBookingOpen(false);
    }
  });

  const renderLocaleSelect = () => (
    <Select
      items={locales?.map((locale) => ({
        label: locale.code,
        value: locale.code,
      }))}
      onSelect={(selected) => changeLocale(selected.value)}
      defaultValue={currentLocale.code}
      className={cl("locale_select")}
    />
  );

  const renderLogo = () => (
    <Link path={homePath} className={cl("logo")}>
      {accessible ? "Cassia Jerusalem" : logo}
    </Link>
  );

  const renderFacebook = () => (
    <Link path={FACEBOOK_URL} className={cl("icon")}>
      {accessible ? "facebook" : facebook}
    </Link>
  );

  const renderInstagram = () => (
    <Link path={INSTAGRAM_URL} className={cl("icon")}>
      {accessible ? "instagram" : instagram}
    </Link>
  );

  const renderDesktop = () => (
    <Container className={cl(["container", rtlClass])}>
      {renderLogo()}
      <div className={cl("nav")}>
        <Nav items={items} />
      </div>

      <div className={cl("right")}>
        {FACEBOOK_URL !== null && renderFacebook()}
        {renderInstagram()}
        {locales?.length > 1 && renderLocaleSelect()}
      </div>
    </Container>
  );

  const renderMobile = () => (
    <Container className={cl(["container", rtlClass, accessibleClass])}>
      <button className={cl("hamburger")} onClick={() => setMenuOpen(true)}>
        {accessible ? "MENU" : hamburger}
      </button>
      {renderLogo()}

      <Modal
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
        aria-label="navigation"
        modalClassname={cl("modal")}
      >
        {locales?.length > 1 && (
          <div className={cl("modal__locale_select")}>
            {renderLocaleSelect()}
          </div>
        )}

        <NavMobile items={items} />
        <div
          className={cl("modal__social")}
          onClick={() => setMenuOpen(!menuOpen)}
        >
          {FACEBOOK_URL !== null && renderFacebook()}
          {renderInstagram()}
        </div>
      </Modal>
    </Container>
  );

  return (
    <header
      className={cl([
        "root",
        (scrolled || currentLocale.code === "he") && "scrolled",
        accessibleClass,
      ])}
    >
      <div className={cl("scrollable")}>
        {(mediumUp || accessible) && renderDesktop()}
        {mediumDown && !accessible && renderMobile()}
        <div className={cl(["booking_script_container", rtlClass])}>
          <ScriptBookingWidget currentLocale={currentLocale} />
        </div>
      </div>
    </header>
  );
}
