import { createContext, useContext, useState, useEffect } from "react";
import { debounce } from "@scripts/utilities/helpers";

const ViewportContext = createContext({});

ViewportProvider.propTypes = {
  children: PropTypes.node,
};

const BREAKPOINTS = {
  xsmall: 400,
  small: 575,
  mobile: 768,
  semitablet: 991,
  tablet: 1024,
  medium: 1180,
  large: 1366,
  xlarge: 1440,
};

export function ViewportProvider({ children }) {
  const [breakpoint, setBreakpoint] = useState(null);

  useEffect(() => {
    const resizeFn = debounce(150, onResize);
    window.addEventListener("resize", resizeFn);

    onResize();

    return () => window.removeEventListener("resize", resizeFn);
  }, []);

  const viewport = (width) => {
    let state = null;

    for (let key in BREAKPOINTS) {
      width < BREAKPOINTS[key] && !state ? (state = key) : null;
    }

    if (state === null) {
      return "xlarge";
    }

    return state !== breakpoint ? state : false;
  };

  const onResize = () => setBreakpoint(viewport(window.innerWidth));

  const is = (name) => BREAKPOINTS[breakpoint] <= BREAKPOINTS[name];

  const not = (name) => BREAKPOINTS[breakpoint] > BREAKPOINTS[name];

  return (
    <ViewportContext.Provider
      value={{
        viewport: {
          is,
          not,
        },
        xsmall: BREAKPOINTS[breakpoint] <= BREAKPOINTS.xsmall,
        xsmallUp: BREAKPOINTS[breakpoint] > BREAKPOINTS.xsmall,
        smallUp: BREAKPOINTS[breakpoint] > BREAKPOINTS.small,
        mobileUp: BREAKPOINTS[breakpoint] > BREAKPOINTS.mobile,
        semitablet: BREAKPOINTS[breakpoint] > BREAKPOINTS.mobile,
        tabletUp: BREAKPOINTS[breakpoint] > BREAKPOINTS.tablet,
        mediumUp: BREAKPOINTS[breakpoint] > BREAKPOINTS.medium,
        largeUp: BREAKPOINTS[breakpoint] > BREAKPOINTS.large,
        xlargeUp: BREAKPOINTS[breakpoint] > BREAKPOINTS.xlarge,

        xsmallDown: BREAKPOINTS[breakpoint] <= BREAKPOINTS.xsmall,
        smallDown: BREAKPOINTS[breakpoint] <= BREAKPOINTS.small,
        mobileDown: BREAKPOINTS[breakpoint] <= BREAKPOINTS.mobile,
        tabletDown: BREAKPOINTS[breakpoint] <= BREAKPOINTS.tablet,
        mediumDown: BREAKPOINTS[breakpoint] <= BREAKPOINTS.medium,
        largeDown: BREAKPOINTS[breakpoint] <= BREAKPOINTS.large,
        xlargeDown: BREAKPOINTS[breakpoint] <= BREAKPOINTS.xlarge,
        breakpoint,
      }}
    >
      {children}
    </ViewportContext.Provider>
  );
}

export default function useViewportContext() {
  return useContext(ViewportContext);
}
