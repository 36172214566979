import { createContext, useContext, useState, useEffect, useMemo } from "react";
import cookies from "js-cookie";
import { LOCALES, DEFAULT_LOCALE } from "./";
import { useAppState } from "@scripts/providers";
import { IntlProvider } from "react-intl";

const LocalizationContext = createContext({});

const APP_LOCALE = "APP_LOCALE";

LocalizationProvider.propTypes = {
  children: PropTypes.node,
};

/*

Language code cookie set when a user selects a language.
When the page loads, if there is a locale preference saved (cookie), set that as the initial locale
If there is no preference saved, attempt to set the locale based on the detected language

When the locale is set, we update the path to use that locale's base (eg /he)

If we can't detect the language from the browser, use the default locale (EN)
*/

export function LocalizationProvider({ children }) {
  const { history, location } = useAppState();

  const [currentLocale, setCurrentLocale] = useState(getInitialLocale());

  useEffect(() => {
    const nextPath = getNextPathFromLocale(currentLocale);

    if (nextPath !== location.pathname) {
      history.replace(nextPath);
    }
  }, []);

  function languageToValidLocaleCode(language) {
    return language ? language?.split("-")[0] : null;
  }

  function findLocale(langCode) {
    return LOCALES.find((locale) => locale.code === langCode);
  }

  function getInitialLocale() {
    const { language } = window.navigator;
    const cookie = cookies.get(APP_LOCALE);

    if (cookie) {
      return findLocale(cookie) || DEFAULT_LOCALE;
    }

    const code = languageToValidLocaleCode(language);

    return findLocale(code) || DEFAULT_LOCALE;
  }

  const hasAlternateLocaleInPath = () => {
    const codes = LOCALES.map((locale) => locale.code);
    const pattern = new RegExp(`^\/(${codes.join("|")})(\/|$)`);
    return location.pathname.match(pattern);
  };

  const getLocaleFromPath = () => {
    const matchingLocale = hasAlternateLocaleInPath();

    if (matchingLocale) {
      return LOCALES.find((locale) => locale.code == matchingLocale[1]);
    }

    return DEFAULT_LOCALE;
  };

  const getNextPathFromLocale = (nextLocale) => {
    const localeFromPath = getLocaleFromPath();

    const pattern = new RegExp(`^/${localeFromPath.code}`);

    const formattedPath = window.location.pathname.replace(pattern, "");

    const nextBase =
      nextLocale.code === DEFAULT_LOCALE.code ? "" : `/${nextLocale.code}`;

    return `${nextBase}${formattedPath}`;
  };

  const changeLocale = (localeCode) => {
    const nextLocale = findLocale(localeCode);

    if (nextLocale) {
      cookies.set(APP_LOCALE, localeCode);

      const nextPath = getNextPathFromLocale(nextLocale);

      setCurrentLocale(nextLocale);

      // To load language onto booking script. It requires a full page reload in order
      // to reflect the language update
      window.location.href = nextPath;
    }
  };

  const onError = (err) => {
    if (err.code === "MISSING_TRANSLATION") {
      return console.warn("Missing translation:", err.descriptor?.id);
    }
    throw err;
  };

  const contextValue = useMemo(
    () => ({
      locales: LOCALES,
      defaultLocale: DEFAULT_LOCALE,
      currentLocale,
      changeLocale,
      getLocaleFromPath,
      homePath:
        currentLocale.path === DEFAULT_LOCALE.path ? "/" : currentLocale.path,
      rtlEnabled: currentLocale?.dir == "rtl",
      rtlClass: currentLocale?.dir == "rtl" ? "rtl" : "",
    }),
    [currentLocale]
  );

  return (
    <LocalizationContext.Provider value={contextValue}>
      <IntlProvider
        locale={currentLocale.code}
        defaultLocale={DEFAULT_LOCALE.code}
        messages={currentLocale.messages}
        onError={onError}
      >
        {children}
      </IntlProvider>
    </LocalizationContext.Provider>
  );
}

export function useLocalization() {
  return useContext(LocalizationContext);
}
