import { calendarArrow } from "@scripts/widgets/icons";
import { Controller } from "react-hook-form/dist/index.ie11";
import { Label, ErrorMessage, useFormContext } from "@scripts/form";
import { v4 as uuidv4 } from "uuid";
import styles from "./day_picker_input.scss";
import { _classes } from "@scripts/utilities/helpers";
import { DayPicker } from "react-day-picker";
import dayjs from "dayjs";

const cl = _classes(styles);

const UUID = uuidv4();

DayPickerInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  dayPickerProps: PropTypes.object,
  fromMonth: PropTypes.object,
  format: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  mode: PropTypes.string,
  nunberOfMonths: PropTypes.number,
  onSelect: PropTypes.func,
  disabledDays: PropTypes.object,
};

DayPickerInput.defaultProps = {
  name: "date",
  format: "LL",
  dayPickerProps: {},
  className: "",
  mode: "single",
  numberOfMonths: 2,
  onSelect: () => null,
};

export default function DayPickerInput({
  name,
  placeholder,
  label,
  rules,
  dayPickerProps,
  fromMonth,
  disabledDays,
  className,
  defaultValue = {},
  format,
  mode,
  numberOfMonths,
  onSelect,
}) {
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value }) => (
        <div className={cl(["root", className])}>
          <Label value={label} htmlFor={id} />
          <div className={cl("control")}>
            <DayPickerUncontrolled
              value={value}
              onSelect={(range) => {
                if (value?.from && value?.to) {
                  return null;
                }

                onChange(range);
                onSelect(range);
              }}
              disabledDays={disabledDays}
              mode={mode}
              numberOfMonths={numberOfMonths}
              onDayClick={(day) => {
                if (value?.from && value?.to) {
                  const range = { from: day, to: null };
                  onChange(range);
                  onSelect(range);
                }
              }}
            />
          </div>
          <ErrorMessage error={errors[name]} />
        </div>
      )}
    />
  );
}

NavbarElement.propTypes = {
  month: PropTypes.string,
  onNextClick: PropTypes.func,
  onPreviousClick: PropTypes.func,
};

function NavbarElement({ month, onNextClick, onPreviousClick }) {
  return (
    <div className={"DayPicker-Caption-Custom"}>
      <button
        type="button"
        onClick={() => onPreviousClick(null)}
        className={"DayPicker-Arrow previous"}
      >
        {calendarArrow}
      </button>
      <span>{dayjs(month).format("MMMM YYYY")}</span>
      <button
        type="button"
        onClick={() => onNextClick(null)}
        className={"DayPicker-Arrow next"}
      >
        {calendarArrow}
      </button>
    </div>
  );
}

export function DayPickerUncontrolled({
  onSelect,
  onDayClick,
  mode,
  numberOfMonths,
  disabledDays,
  value,
}) {
  const today = new Date();

  const nonSelectableDays = disabledDays || [{ before: today }];

  return (
    <DayPicker
      mode={mode}
      defaultMonth={today}
      selected={value}
      onSelect={onSelect}
      disabled={nonSelectableDays}
      fromMonth={today}
      numberOfMonths={numberOfMonths}
      fixedWeeks
      onDayClick={onDayClick}
      formatters={{
        formatWeekdayName: (date) => dayjs(date).format("dd").slice(0, 1),
        formatCaption: (date) => dayjs(date).format("MMMM"),
      }}
    />
  );
}
