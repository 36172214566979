// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".rooms_divider__root___c8IUT{display:block;margin:50px 0px}.rooms_divider__divider___EMhbO{height:1px;width:100%;background-color:#000}", "",{"version":3,"sources":["webpack://./src/scripts/partials/rooms/RoomsDivider/rooms_divider.scss","webpack://./src/styles/global/_colors.scss"],"names":[],"mappings":"AAEA,6BACE,aAAA,CACA,eAAA,CAGF,gCACE,UAAA,CACA,UAAA,CACA,qBCRc","sourcesContent":["@import \"@styles/global/_parts.scss\";\n\n.root {\n  display: block;\n  margin: 50px 0px;\n}\n\n.divider {\n  height: 1px;\n  width: 100%;\n  background-color: $primary_black;\n}\n","//theme colors\n$primary_brown: #a2662f;\n$primary_black: #000;\n$primary_white: #fff;\n$secondary_green: #395437;\n$secondary_grey: #d1cbbc;\n$secondary_orange: #f8992e;\n$secondary_gold: #ffc709;\n$secondary_yellow: #fee000;\n\n//other found colors\n$black_100: #e8e5db;\n$black_300: #6b6b6b;\n$secondary_beige: #f3f1ed;\n$secondary_tan: #feebd5;\n\n$white: #fff;\n$black: $primary_black;\n$errorRed: #e01c1c;\n$blue: #4d90fe;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "rooms_divider__root___c8IUT",
	"divider": "rooms_divider__divider___EMhbO"
};
export default ___CSS_LOADER_EXPORT___;
