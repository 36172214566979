export const loadingDuration = 2000;

export const FACEBOOK_URL = null;

export const INSTAGRAM_URL = "https://www.instagram.com/cassia_israel/";

export const LOCATIONS = [
  {
    title: "Cassia Hotel Jerusalem",
    address1: "King David Street 32",
    address2: "Jerusalem 9410158",
    country: "Israel",
    phone: "+972 2 569 5555",
    email: "reservation@cassiahotel.com",
  },
  // {
  //   title: "Cassia Hotel Tiberias",
  //   address1: "King David Street 32",
  //   address2: "Tiberias 9410158",
  //   country: "Israel",
  //   phone: "+972 2 569 5555",
  //   email: "reservation@cassiahotel.com",
  // },
];

export const LOCATIONS_HE = [
  {
    title: "מלון קסיה ירושלים",
    address1: "רחוב דוד המלך 32",
    address2: "ירושלים 9410158",
    country: "ישראל",
    phone: "+972 2 569 5555",
    email: "reservation@cassiahotel.com",
  },
  // {
  //   title: "מלון קסיה טבריה",
  //   address1: "רחוב דוד המלך 32",
  //   address2: "טבריה 9410158",
  //   country: "ישראל",
  //   phone: "+972 2 569 5555",
  //   email: "reservation@cassiahotel.com",
  // },
];

export const PLACEHOLDER_IMAGE =
  "https://www.coasterfurniture.com/wp-content/uploads/apperson-cushioned-back-arm-chair.png";

export const BOOKING_ENGINE_CHAIN_ID = 143;
export const BOOKING_ENGINE_HOTEL_ID = 10359;
