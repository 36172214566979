import styles from "./error_message.scss";
import { Text } from "@scripts/widgets";

ErrorMessage.propTypes = {
  error: PropTypes.object,
};

export default function ErrorMessage({ error }) {
  return error ? (
    <Text
      className={styles.root}
      text={error?.message || "form.defaultError"}
      component="span"
      variant="body4"
      weight="bold"
    />
  ) : null;
}
