// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".label__root___ora_J{position:absolute;top:50%;transform:translate(0, -50%);z-index:1;transition:all 100ms ease-in-out;line-height:16px}.label__root___ora_J.label__dark___dykFf{color:#000}.label__root___ora_J.label__light___U3Zk5{color:#fff;left:15px}", "",{"version":3,"sources":["webpack://./src/scripts/form/Label/label.scss","webpack://./src/styles/global/_colors.scss"],"names":[],"mappings":"AAEA,qBACE,iBAAA,CACA,OAAA,CACA,4BAAA,CAEA,SAAA,CACA,gCAAA,CACA,gBAAA,CAEA,yCACE,UCVY,CDad,0CACE,UAAA,CACA,SAAA","sourcesContent":["@import \"@styles/global/_parts.scss\";\n\n.root {\n  position: absolute;\n  top: 50%;\n  transform: translate(0, -50%);\n\n  z-index: 1;\n  transition: all 100ms ease-in-out;\n  line-height: 16px;\n\n  &.dark {\n    color: $black;\n  }\n\n  &.light {\n    color: $white;\n    left: 15px;\n  }\n}\n","//theme colors\n$primary_brown: #a2662f;\n$primary_black: #000;\n$primary_white: #fff;\n$secondary_green: #395437;\n$secondary_grey: #d1cbbc;\n$secondary_orange: #f8992e;\n$secondary_gold: #ffc709;\n$secondary_yellow: #fee000;\n\n//other found colors\n$black_100: #e8e5db;\n$black_300: #6b6b6b;\n$secondary_beige: #f3f1ed;\n$secondary_tan: #feebd5;\n\n$white: #fff;\n$black: $primary_black;\n$errorRed: #e01c1c;\n$blue: #4d90fe;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "label__root___ora_J",
	"dark": "label__dark___dykFf",
	"light": "label__light___U3Zk5"
};
export default ___CSS_LOADER_EXPORT___;
