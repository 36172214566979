import styles from "./promo_popup.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text, Modal, CTA, Image } from "@scripts/widgets";
import { useEffect, useState, useMemo } from "react";
import useAppState from "@scripts/providers/AppProvider";
import { useLocalization } from "@scripts/localization";

const cl = _classes(styles);

PromoPopup.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default function PromoPopup({ className = "" }) {
  const { byTemplate } = useAppState();
  const [visible, toggle] = useState(false);
  const { currentLocale } = useLocalization();

  const page = useMemo(
    () => byTemplate("popup").find((p) => p.localeCode === currentLocale.code),
    [currentLocale]
  );

  useEffect(() => {
    setTimeout(() => page && toggle(true), 3000);
  }, []);

  return (
    <Modal
      open={visible}
      onClose={() => toggle(false)}
      aria-label="promo popup"
      modalClassname={cl("root")}
    >
      {page?.image1 && (
        <div className={cl("image")}>
          <Image src={page?.image1} />
        </div>
      )}

      <div className={cl("content")}>
        {page?.h1 !== "" && (
          <div className={cl("title_wrap")}>
            <Text
              text={page?.h1}
              component="h2"
              variant="h4"
              className={cl("title")}
            />
          </div>
        )}

        <div className={cl("message_wrap")}>
          {page?.blurb1 !== "" && (
            <Text text={page?.blurb1} className={cl("mesasage")} />
          )}
          {page?.h2 !== "" ||
            (null && (
              <CTA
                text="common.learnMore"
                variant="secondary"
                onClick={toggle}
                link={page?.h2}
                className={cl("cta")}
              />
            ))}
        </div>
      </div>
    </Modal>
  );
}
