import { useEffect, useRef } from "react";
import Slick from "react-slick";
import { Image } from "@scripts/widgets";
import styles from "./carousel.scss";
import { _classes } from "@scripts/utilities/helpers";
import { arrow } from "../icons";
const cl = _classes(styles);
// when using the carousel in your component:
// wrap the carousel in a container
// it will inherit the size of it's wrapper :)

Carousel.propTypes = {
  slides: PropTypes.array,
  settings: PropTypes.object,
  className: PropTypes.string,
  keyboardControl: PropTypes.bool,
  onMount: PropTypes.func,
};
Carousel.defaultProps = {
  settings: {
    arrows: true,
    dots: true,
    useTransform: false,
  },
};

export default function Carousel(props) {
  const { slides, className, settings, keyboardControl } = props;

  const slick = useRef();

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    if (props.onMount) {
      props.onMount(slick);
    }
    return () => {
      if (typeof window !== "undefined") {
        window.removeEventListener("keydown", handleKeyDown);
      }
    };
  }, []);

  const imageComponentFor = (slide) => {
    const imgProps = {
      align: slide.image1_anchor,
      src: slide.src || slide.image1,
      alt: slide.alt || slide.image1_alt_text,
    };
    return <Image {...imgProps} />;
  };

  const handleKeyDown = (e) => {
    if (keyboardControl && slick) {
      if (
        !document.activeElement ||
        document.activeElement.tagName !== "INPUT"
      ) {
        if (e.key === "ArrowLeft" || e.key === "Left") {
          slick.current.slickPrev();
        } else if (e.key === "ArrowRight" || e.key === "Right") {
          slick.current.slickNext();
        }
      }
    }
  };

  if (slides.length === 1) {
    return (
      <div
        className={`carousel-with-one-image ${className}`}
        style={{ padding: 0 }}
      >
        {imageComponentFor(slides[0])}
      </div>
    );
  }

  return (
    <div className={`carousel ${styles.root} ${className}`}>
      {settings.arrows ? (
        <>
          {" "}
          <button
            onClick={() => slick?.current?.slickPrev()}
            onKeyDown={handleKeyDown}
            aria-label="prev"
            className={cl(["arrow", "prev"])}
            type="button"
          >
            {arrow}
          </button>
          <button
            onClick={() => slick?.current?.slickNext()}
            onKeyDown={handleKeyDown}
            aria-label="next"
            className={cl(["arrow", "next"])}
            type="button"
          >
            {arrow}
          </button>
        </>
      ) : null}
      <Slick ref={slick} {...settings}>
        {slides.map((slide, i) => {
          return <div key={i}>{imageComponentFor(slide)}</div>;
        })}
      </Slick>
    </div>
  );
}
