import styles from "./label.scss";
import { Text } from "@scripts/widgets";
import { _classes } from "@scripts/utilities/helpers";

const cl = _classes(styles);

Label.propTypes = {
  value: PropTypes.string,
  htmlFor: PropTypes.string,
  variant: PropTypes.string,
  className: PropTypes.string,
};

export default function Label({
  value,
  htmlFor,
  variant = "dark",
  className = "",
}) {
  return value ? (
    <Text
      text={value}
      component="label"
      variant="body2"
      className={cl(["root", variant, className])}
      htmlFor={htmlFor}
      weight="bold"
    />
  ) : null;
}
