import { useEffect, useMemo } from "react";
import { LazyBoundary } from "react-imported-component";
import { Switch, Route, Redirect } from "react-router-dom";
import useAppState from "@scripts/providers/AppProvider";
import ErrorBoundary from "@scripts/utilities/ErrorBoundary";
import { AnimatePresence, motion } from "@scripts/utilities/FramerMotion";
import viewsPaths from "@scripts/utilities/viewPaths";
import { useLocalization } from "./localization";

const doNotRoute = {
  root: true,
  header: true,
  footer: true,
  options: true,
  menu: true,
  menu_section: true,
  gallery_category: true,
};

Routes.propTypes = {};

export default function Routes() {
  const { content, location } = useAppState();
  const { currentLocale } = useLocalization();

  const routes = useMemo(() => createBaseRoutes(), []);

  function setPage(page) {
    return page;
  }

  function createBaseRoutes() {
    return Object.keys(content)
      .map((key) => {
        const page = content[key];
        const template = page.template.toLowerCase().replace(/_/g, "");
        const Component = viewsPaths[`${template}`] || viewsPaths["default"];
        const formattedPage = setPage(page);

        if (!doNotRoute[page.template]) {
          return (
            <Route
              exact
              key={formattedPage.id}
              path={formattedPage.displayPath} //formattedPath set in index.js
              render={(props) => (
                <LazyBoundary fallback={<div />}>
                  <ErrorBoundary>
                    <Component page={formattedPage} {...props} />
                  </ErrorBoundary>
                </LazyBoundary>
              )}
            />
          );
        }
      })
      .concat(
        <Route
          path="/"
          key="redirectAllElse"
          render={() => {
            return (
              <Redirect
                to={currentLocale.code == "en" ? "/" : currentLocale.path}
              />
            );
          }}
        />
      );
  }

  return (
    <main>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.3, delay: 0 }}
          className="page"
          key={location.key}
          layout
        >
          <Switch location={location}>{routes}</Switch>
        </motion.div>
      </AnimatePresence>
    </main>
  );
}
