import styles from "./notice_popup.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Modal } from "@scripts/widgets";
import { useEffect, useState, useMemo } from "react";
import useAppState from "@scripts/providers/AppProvider";
import { useLocalization } from "@scripts/localization";
import { closeX, logo, tree } from "../../widgets/icons";

const cl = _classes(styles);

NoticePopup.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default function NoticePopup({ className = "" }) {
  const { byTemplate } = useAppState();
  const [visible, toggle] = useState(false);
  const { currentLocale } = useLocalization();

  const page = useMemo(
    () =>
      byTemplate("notice_popup").find(
        (p) => p.localeCode === currentLocale.code
      ),
    [currentLocale]
  );

  useEffect(() => {
    setTimeout(() => page && toggle(true), 3000);
  }, []);

  return (
    <Modal
      open={visible}
      onClose={() => toggle(false)}
      aria-label="King Solomon Hotel is now Cassia Jerusalem."
      modalClassname={cl("root")}
      closeIcon={closeX}
      buttonBkg={"orange"}
    >
      <div className={cl("content")}>
        <div className={cl("logo")}>{logo}</div>
        <div className={cl("message_wrap")}>
          <div
            className={cl("message")}
            dangerouslySetInnerHTML={{ __html: page.blurb1 }}
          />
        </div>
        <div className={cl("tree")}>{tree}</div>
      </div>
    </Modal>
  );
}
