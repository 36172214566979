import browser from "bowser";
import InvalidBrowser from "@scripts/utilities/InvalidBrowser/InvalidBrowser";
import { Footer, PromoPopup, CookiePopup } from "@scripts/partials";
import { Header } from "@scripts/partials/header";
import { loadingDuration } from "@scripts/config/appConfig";
import { useLocation } from "react-router-dom";
import Routes from "@scripts/Routes";
import { useEffect, useRef } from "react";
import useAppState from "@scripts/providers/AppProvider";
import { useLocalization } from "@scripts/localization";
import { AnimatePresence, motion } from "@scripts/utilities/FramerMotion";
import NoticePopup from "./partials/NoticePopup/NoticePopup";
import { getQueryParams } from "@scripts/utilities/helpers";
import useViewportContext from "./providers/ViewportProvider";
import { Helmet } from "react-helmet";

App.propTypes = {
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
};

export default function App({ location }) {
  const { byPath, byDisplayPath, accessible } = useAppState();
  const { rtlClass, currentLocale } = useLocalization();
  const { search } = useLocation();

  useEffect(() => {
    // const html = document.querySelector("html");
    // html.classList[accessible ? "add" : "remove"]("accessible");
    const els = document.querySelectorAll(".accessible-styles");

    [
      ...document.querySelectorAll("link[rel='stylesheet']"),
      ...document.querySelectorAll("style"),
    ].forEach((sheet) => (sheet.disabled = accessible));

    setTimeout(() => {
      [
        ...document.querySelectorAll("link[rel='stylesheet']"),
        ...document.querySelectorAll("style:not(.accessible-styles"),
      ].forEach((sheet) => (sheet.disabled = accessible));
    }, 500);

    if (accessible) {
      document.head.insertAdjacentHTML(
        "beforeend",
        `<style class="accessible-styles">
          body {
            font-family: "Arial" !important;
            max-width: 1400px;
            margin: 20px auto;
            padding: 20px;
          }

          nav ul, nav li {
            margin: 0px !important;;
          }

          nav span {
            font-size: 14px !important;;
          }
    
          img, svg{
            display: none !important;
          }

          * {
            cursor: auto !important;
            border-color: black !important;
          }

          *:not(a){ 
            color: black;
          }
        </style>`
      );
    } else {
      els.forEach((el) => document.head.removeChild(el));
    }
  }, [accessible, location.pathname]);
  //get query strings and put them in an array

  //Notice popup, only shows if there is a "from" query string

  //Can pass a search param like such to test the notice popup: ?from=ksh
  const isFromKsh = search && getQueryParams(search).from;

  useEffect(() => {
    pageChangeRoutine();
    setTimeout(hideLoader, loadingDuration);
  }, []);

  useEffect(() => {
    trackPageView(location.pathname);
    pageChangeRoutine();
  }, [location.pathname, location.searchj]);

  const hideLoader = () =>
    (document.querySelector(".site-loader").className +=
      " site-loader--loaded");

  const isValidBrowser = () =>
    !(browser.msie && parseInt(browser.version) < 11);

  const pageChangeRoutine = () => {
    const page = byDisplayPath(location.pathname);
    document.title = page.pagetitle || byPath("/").pagetitle;

    window.scrollTo(0, 0);
  };

  const trackPageView = (page) => {
    const { title } = document;
    if (window.ga) {
      ga("send", "pageview", { page, title });
    }
  };

  const { viewport } = useViewportContext();
  const isMobile = viewport.is("semitablet");

  if (!isValidBrowser()) {
    return <InvalidBrowser />;
  }

  return (
    <div className={`app ${rtlClass}`}>
      <Helmet defer={true}>
        <script src="https://booking.simplex-ltd.com/Scripts/libs/jquery/dist/jquery.js"></script>
        <link
          href={
            currentLocale.code === "en"
              ? "https://booking.simplex-ltd.com/Content/Chains/DefaultTheme/calendar-ltr.min.css"
              : "https://booking.simplex-ltd.com/Content/Chains/DefaultTheme/calendar-rtl.min.css"
          }
          rel="stylesheet"
        />
        <script src="https://booking.simplex-ltd.com/Scripts/EngineScriptsV5/searchEngine.js?v=1233"></script>
      </Helmet>
      <div style={{ marginBottom: "60px" }}>
        <Header />
      </div>
      <AnimatePresence exitBeforeEnter>
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.3, delay: 0 }}
          className="page"
          key={location.key}
        >
          <Routes />
          <Footer />
        </motion.div>
      </AnimatePresence>
      <PromoPopup />
      {isFromKsh && <NoticePopup />}
      <CookiePopup />
    </div>
  );
}
