import styles from "./hero_carousel.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Carousel } from "@scripts/widgets";
import useAppState from "@scripts/providers/AppProvider";

const cl = _classes(styles);

HeroCarousel.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  slides: PropTypes.array,
  settings: PropTypes.object,
};

export default function HeroCarousel({
  children,
  className = "",
  slides = [],
  settings = { arrows: true, dots: false, useTransform: false },
}) {
  const { accessible } = useAppState();

  if (accessible) {
    return <section>{children}</section>;
  }

  return (
    <section className={cl(["root", className])}>
      <Carousel keyboardControl slides={slides} settings={settings} />
      {children}
    </section>
  );
}
