import styles from "./wysiwyg.scss";
import { _classes } from "@scripts/utilities/helpers";
import { useLocalization } from "../../localization";

const cl = _classes(styles);

Wysiwyg.propTypes = {
  html: PropTypes.string,
  className: PropTypes.string,
  disclaimerParagraph: PropTypes.bool, //Last paragraph will be formatted to be smaller than the rest if this is true.
};

export default function Wysiwyg({
  html = "",
  className = "",
  disclaimerParagraph = false,
}) {
  const { rtlClass } = useLocalization();
  return (
    <div
      dangerouslySetInnerHTML={{ __html: html }}
      className={cl([
        "root",
        className,
        rtlClass,
        disclaimerParagraph && "disclaimer",
      ])}
    />
  );
}
