/* Form Component.
 *
 * onSubmit can be a string indicating the name of the endpoint
 * to post to on our server, or a custom function that returns
 * a promise
 */

import { createContext, useContext, useState } from "react";
import { useForm } from "react-hook-form/dist/index.ie11";
import API from "@scripts/utilities/API";

const FormContext = createContext({});

FormProvider.propTypes = {
  children: PropTypes.node,
  onSubmit: PropTypes.oneOfType(["func", "string"]).required,
  additionalData: PropTypes.object,
  hideOnSubmit: PropTypes.bool,
  thankYouClassname: PropTypes.string,
  className: PropTypes.string,
};

export default function FormProvider({
  children,
  onSubmit = "contact_us",
  additionalData = {},
  hideOnSubmit = true,
  thankYouClassname = "",
  className = "form",
}) {
  const {
    handleSubmit,
    control,
    errors,
    register,
    watch,
    setValue,
    getValues,
    formState,
  } = useForm();

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const renderFields = () =>
    success ? (hideOnSubmit ? null : children) : children;

  const renderThankYou = () =>
    success &&
    hideOnSubmit && (
      <div className={thankYouClassname}>
        <p>Thanks for your submission.</p>
      </div>
    );

  const renderError = () =>
    error &&
    !success && (
      <div>
        <p>{error}</p>
      </div>
    );

  const submitHandler = handleSubmit((formData) => {
    let api = new API({ base: API_PATH });
    let submit = api.post.bind(api, onSubmit);

    if (typeof onSubmit === "function") {
      submit = onSubmit;
    }

    submit({ ...formData, ...additionalData })
      .then((resp) => {
        console.log(resp);
        if (resp?.error) {
          return setError(resp?.error.message || "An error occurred.");
        }
        setSuccess(true);
      })
      .catch((error) => {
        console.error(error);
        setError(error?.message || "An error occurred.");
      });
  });

  return (
    <FormContext.Provider
      value={{
        control,
        register,
        errors,
        watch,
        setValue,
        getValues,
        formState,
      }}
    >
      <form className={className} onSubmit={submitHandler}>
        {renderFields()}
        {renderThankYou()}
        {renderError()}
      </form>
    </FormContext.Provider>
  );
}

export function useFormContext() {
  return useContext(FormContext);
}
