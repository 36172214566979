import styles from "./booking_widget_guests.scss";
import { _classes, range } from "@scripts/utilities/helpers";
import { Text, CTA } from "@scripts/widgets";
import AnimateHeight from "react-animate-height";
import useBookingWidgetContext from "../../bookingWidget/BookingWidgetProvider";
import IncrementField from "../IncrementField/IncrementField";
import { useEffect, useState } from "react";

const cl = _classes(styles);

BookingWidgetGuests.propTypes = {
  isHeader: PropTypes.bool,
};

export default function BookingWidgetGuests({ isHeader }) {
  const { formState } = useBookingWidgetContext();
  const roomsValue = formState["rooms"];
  const adultsValue =
    formState["adults"] === undefined ? 1 : formState["adults"];
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (roomsValue > 1) {
      setOpen(true);
    }
  }, [roomsValue || adultsValue]);

  return (
    <div className={cl(["root", isHeader ? "is_header" : ""])}>
      <IncrementField
        name="rooms"
        min={1}
        max={3}
        defaultValue={1}
        alt={isHeader}
      />
      <IncrementField
        label={"adults"}
        name={roomsValue > 1 ? "adults" : "ad1"}
        min={1}
        max={roomsValue > 1 ? roomsValue * 5 : 5}
        hideControls={open}
        defaultValue={1}
        alt={isHeader}
      />
      <IncrementField
        childrenSubtitle={"childrenSubtitle"}
        label="children"
        name={roomsValue > 1 ? "children" : "ch1"}
        min={0}
        max={maxChildrenValue(roomsValue, adultsValue)}
        hideControls={open}
        defaultValue={0}
        alt={isHeader}
      />

      <GuestInfoDropdown open={open} isHeader close={() => setOpen(false)} />
    </div>
  );
}

function maxChildrenValue(roomsValue, adultsValue) {
  if (roomsValue > 1) {
    return roomsValue * 2;
  }
  if (adultsValue && adultsValue === 1 && roomsValue === 1) {
    return 4;
  }
  if (adultsValue && adultsValue === 2) {
    return 3;
  }
  if (adultsValue && adultsValue === 3) {
    return 2;
  }
  if (adultsValue && adultsValue === 4) {
    return 1;
  }

  return 0;
}

function GuestInfoDropdown({ open, isHeader, close }) {
  const { formState } = useBookingWidgetContext();
  const roomsValue = formState["rooms"];

  return (
    <div className={cl("guest_info__dropdown")}>
      <AnimateHeight height={open ? "auto" : 0}>
        {roomsValue &&
          range(roomsValue, 1).map((int) => (
            <li key={int} style={{ display: "flex" }}>
              <Text text={`Room ${int}`} />
              <IncrementField
                alt={isHeader}
                name={`ad${int}`}
                label={" "}
                min={1}
                max={5}
                defaultValue={1}
              />
              <IncrementField
                alt={isHeader}
                name={`ch${int}`}
                label={" "}
                min={0}
                defaultValue={0}
                max={Math.min(Math.abs(formState[`ad${int}`] - 5), 2)}
              />
            </li>
          ))}
        {isHeader && (
          <li className={cl("guest_info__dropdown__close")}>
            <CTA
              text="common.close"
              onClick={() => close()}
              size="small"
              submit
              buttonOnly
            />
          </li>
        )}
      </AnimateHeight>
    </div>
  );
}
