import styles from "./large_image_text.scss";
import { _classes } from "@scripts/utilities/helpers";
import {
  Text,
  Carousel,
  Container,
  Image,
  CTA,
  Wysiwyg,
} from "@scripts/widgets";
import useAppState from "@scripts/providers/AppProvider";

LargeImageText.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.string,
  html: PropTypes.string,
  image: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  cta: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  className: PropTypes.string,
  fullWidthTextOnly: PropTypes.bool,
  textDirectionOverride: PropTypes.string,
};

export default function LargeImageText({
  title,
  subtitle,
  description,
  html,
  image,
  cta,
  className = "",
  fullWidthTextOnly,
  carouselSlides = [],
  carouselSettings,
  textDirectionOverride,
}) {
  const { accessible } = useAppState();
  const cl = accessible ? () => null : _classes(styles);

  return (
    <section className={cl(["root", className])}>
      <Container>
        <div
          className={cl(["inner", fullWidthTextOnly && "fullWidthTextOnly"])}
        >
          <div
            className={cl([
              "image",
              carouselSlides.length && "carouselImages",
              fullWidthTextOnly && "fullWidthNoImage",
            ])}
          >
            {carouselSlides.length ? (
              <figure
                className={cl(["image"])}
                style={{
                  position: "relative",
                  margin: 0,
                }}
              >
                <CarouselImages
                  slides={carouselSlides}
                  settings={carouselSettings}
                />
              </figure>
            ) : (
              <Image src={image?.src} alt={image?.alt} />
            )}
          </div>
          <div className={cl("details")}>
            <Text
              text={subtitle}
              component="h3"
              font="secondary"
              className={cl(["subtitle", textDirectionOverride])}
            />
            <Text
              text={title}
              variant="h3"
              component="h1"
              className={cl(["title", textDirectionOverride])}
            />
            <div style={{ whiteSpace: "pre-wrap" }}>
              {description && (
                <Text
                  text={description}
                  component="p"
                  className={cl(["description", textDirectionOverride])}
                />
              )}
            </div>
            {html && (
              <Wysiwyg
                disclaimerParagraph
                html={html}
                className={cl(["description", textDirectionOverride])}
              />
            )}
            {cta && (
              <CTA
                link={cta?.link}
                text={cta?.text}
                variant="outline_transparent"
                className={cl("cta")}
              />
            )}
          </div>
        </div>
      </Container>
    </section>
  );
}

export const CarouselImages = ({ slides = [], settings = {} }) => {
  const initCarouselSettings = {
    arrows: true,
    dots: true,
    autoplay: true,
    infinite: true,
  };
  return (
    <Carousel
      slides={slides}
      settings={{ ...initCarouselSettings, ...settings }}
    />
  );
};
