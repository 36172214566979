import { useEffect, useState } from "react";
import styles from "./accordion.scss";
import AnimateHeight from "react-animate-height";
import { add, minus } from "@scripts//widgets/icons";
import { _classes } from "@scripts/utilities/helpers";
import { Text } from "@scripts/widgets";

const cl = _classes(styles);

Accordion.propTypes = {
  title: PropTypes.string,
  titleComponent: PropTypes.node,
  children: PropTypes.node,
  className: PropTypes.string,
  defaultExpanded: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default function Accordion({
  title,
  children,
  className = "",
  titleComponent,
  defaultExpanded = false,
  disabled = false,
}) {
  const [expanded, setExpanded] = useState(defaultExpanded);

  useEffect(() => {
    setExpanded(defaultExpanded);
  }, [defaultExpanded]);

  const renderTitle = () => {
    if (titleComponent) return titleComponent;

    return (
      <Text
        text={title}
        variant={"h4"}
        component={"h4"}
        className={styles.title}
        weight="bold"
      />
    );
  };

  return (
    <article className={cl(["root", expanded && "expanded", className])}>
      <button
        className={cl(["header", disabled && "disabled"])}
        type="button"
        onClick={() => !disabled && setExpanded(!expanded)}
      >
        {renderTitle()}
        {!disabled && (
          <div className={cl("icon")}>{expanded ? minus : add}</div>
        )}
      </button>

      <AnimateHeight height={expanded ? "auto" : 0} className={cl("content")}>
        {children}
      </AnimateHeight>
    </article>
  );
}
