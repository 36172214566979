import styles from "./dining_menus.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text } from "@scripts/widgets";
import { useAppState } from "@scripts/providers";
import { useState } from "react";
const cl = _classes(styles);

DiningMenus.propTypes = {
  title: PropTypes.string,
  menus: PropTypes.array,
};

export default function DiningMenus({ title, menus }) {
  const { childrenById } = useAppState();

  const [selectedMenu, selectMenu] = useState(menus[0]);

  const sections = childrenById(selectedMenu?.id);

  const renderHeader = () => (
    <div className={cl("header")}>
      <Text text={title} variant="h3" component="h2" className={cl("title")} />
      <nav className={cl("filters")}>
        {menus?.map((menu) => (
          <button
            onClick={() => selectMenu(menu)}
            className={cl(["filter", selectedMenu?.id == menu.id && "active"])}
          >
            <Text text={menu.linktitle} font="secondary" />
          </button>
        ))}
      </nav>
    </div>
  );

  const renderSections = () =>
    sections?.map((section) => (
      <article key={section.id} className={cl("section")}>
        <Text
          text={section.h1}
          variant="body1"
          component="h3"
          font="secondary"
          className={cl("section_title")}
        />
        {renderItems(section.fieldgroup1)}
      </article>
    ));

  const renderItems = (items) => (
    <ul className={cl("items")}>
      {items?.map((item) => (
        <li key={item.id} className={cl(["item", items.length === 2 ? "flat_row" : ""])}>
          <Text text={item.h1} variant="h6" component="h4" weight="bold" />
          <Text text={item.blurb1} variant="body1" component="p" />
          {/* <Text text={item.h2} variant="body3" component="span" /> */}
        </li>
      ))}
    </ul>
  );
  

  return (
    <section className={cl(["root"])}>
      <div className={cl("container")}>
        <div className={cl("inner")}>
          <div className={cl("inner_container")}>
            {renderHeader()}
            {renderSections()}
          </div>
        </div>
      </div>
    </section>
  );
}
