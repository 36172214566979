import EN_LOCALE from "./en/en.locale";
import HE_LOCALE from "./he/he.locale";
// import FR_LOCALE from "./fr/fr.locale";
// import RU_LOCALE from "./ru/ru.locale";

const LOCALES = [EN_LOCALE, HE_LOCALE];

export default LOCALES;

export const DEFAULT_LOCALE = LOCALES[0];
