export const parse = (json, fallback = false) => {
  try {
    if (json === null || json === "") {
      return fallback;
    }

    return JSON.parse(json) || fallback;
  } catch (e) {
    console.error(e);
    return fallback;
  }
};

// Creates a range (array) of numbers.
export const range = (integer, start = 0) =>
  [...Array(parseInt(integer)).keys()].map((i) => i + parseInt(start));

// Capitalize a string.
export const capitalize = (s) => {
  if (typeof s !== "string") return "";
  return s.charAt(0).toUpperCase() + s.slice(1);
};

// abbreviate class name with a prefix
export const _classes = (styles) => (name) => {
  if (typeof name === "string") {
    return styles[name] || name || "";
  }

  if (Array.isArray(name)) {
    return name.map((n) => styles[n] || n || "").join(" ");
  }

  return "";
};

// Limit how often a function fires.
// Great for event listeners like onResize
// delay in ms
export const debounce = (delay, fn) => {
  let time = Date.now();

  return function debounced(e) {
    if (time + delay - Date.now() < 0) {
      fn(e);
      time = Date.now();
    }
  };
};

//sleep
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

// get the roomcode for the room type by title

export const getRoomCode = (title) => {
  switch (title) {
    case "High Floor Suite":
      return "211SuiHiRo";
    case "Low Floor Suite":
      return "221SuiLoRo";
    case "Junior Suite":
      return "241JSRoom";
    case "Grand Deluxe":
      return "281GRDLXRo";
    case "Deluxe Balcony":
      return "291DLXbaRo";
    case "Deluxe":
      return "271DLXRoom";
    case "Deluxe Old City View":
      return "301DLXolRo";
    case "Deluxe Pool View":
      return "311DLXPVRo";
  }
};

export function getQueryParams(url) {
  const paramArr = url.slice(url.indexOf("?") + 1).split("&");
  const params = {};
  paramArr.map((param) => {
    const [key, val] = param.split("=");
    params[key] = decodeURIComponent(val);
  });
  return params;
}
