import styles from "./header_booking_widget.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text, CTA } from "@scripts/widgets";
import React from "react";
import { useLocalization } from "@scripts/localization";
import useAppState from "@scripts/providers/AppProvider";
import ReactDOM from "react-dom";
import { closeIcon } from "@scripts/widgets/icons";
import { AnimatePresence, motion } from "@scripts/utilities/FramerMotion";
import useBookingWidgetContext from "../../bookingWidget/BookingWidgetProvider";
import BookingWidgetDates from "../../bookingWidget/BookingWidgetDates/BookingWidgetDates";
import BookingWidgetGuests from "../../bookingWidget/BookingWidgetGuests/BookingWidgetGuests";

const cl = _classes(styles);

HeroBookingWidget.propTypes = {
  open: PropTypes.bool,
  closeHandler: PropTypes.func,
};

export default function HeroBookingWidget({ open, closeHandler }) {
  const { rtlClass } = useLocalization();
  const { accessible } = useAppState();
  const { onSubmit } = useBookingWidgetContext();

  if (accessible) {
    return (
      open && (
        <article className={cl(["root", rtlClass])}>
          <button onClick={closeHandler} type="button" className={cl("close")}>
            <Text text="common.close" />
          </button>

          <div className={cl("container")}>
            <form className={cl("inner")} onSubmit={onSubmit}>
              <Text text="common.bookARoom" font="secondary" />
              <BookingWidgetDates numberOfMonths={1} mobile />
              <BookingWidgetGuests isHeader />
              <CTA
                submit={true}
                text="booking.checkAvailability"
                variant="primary"
                size="large"
                onClick={closeHandler}
              />
            </form>
          </div>
        </article>
      )
    );
  }

  return ReactDOM.createPortal(
    <AnimatePresence exitBeforeEnter>
      {open && (
        <motion.article
          className={cl(["root", rtlClass])}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ ease: "easeInOut", duration: 0.3, delay: 0 }}
          key={location.key}
        >
          <button onClick={closeHandler} type="button" className={cl("close")}>
            {closeIcon}
          </button>

          <div className={cl("container")}>
            <form className={cl("inner")} onSubmit={onSubmit}>
              <Text text="common.bookARoom" font="secondary" />
              <BookingWidgetDates numberOfMonths={1} mobile />
              <BookingWidgetGuests isHeader />
              <CTA
                submit={true}
                text="booking.checkAvailability"
                variant="primary"
                size="large"
                onClick={closeHandler}
              />
            </form>
          </div>
        </motion.article>
      )}
    </AnimatePresence>,
    document.body
  );
}
