import styles from "./cookie_popup.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text, CTA } from "@scripts/widgets";
import React, { useEffect, useState, useMemo } from "react";
import useAppState from "@scripts/providers/AppProvider";
import { useLocalization } from "@scripts/localization";
import cookies from "js-cookie";

const cl = _classes(styles);

const APP_ACCEPT_COOKIES = "CASSIA_ACCEPT_COOKIES";

CookiePopup.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default function CookiePopup({ className = "" }) {
  const { byTemplate } = useAppState();
  const [visible, toggle] = useState(false);
  const { currentLocale } = useLocalization();
  const cookie = cookies.get(APP_ACCEPT_COOKIES);

  const page = useMemo(
    () => byTemplate("cookie").find((p) => p.localeCode === currentLocale.code),
    [currentLocale]
  );

  useEffect(() => {
    setTimeout(() => {
      if (page && !cookie) {
        toggle(true);
      }
    }, 1000);
  }, []);

  if (!page || cookie) {
    return null;
  }

  return ReactDOM.createPortal(
    <article
      aria-label="cookie popup"
      className={cl(["root", visible && "visible"])}
    >
      <div className={cl("container")}>
        <Text
          text={page?.blurb1}
          className={cl("text")}
          component="p"
          variant="body3"
          weight="bold"
        />
        <CTA
          text="common.accept"
          variant="cookie"
          onClick={() => {
            toggle(false);
            cookies.set(APP_ACCEPT_COOKIES, true);
          }}
          className={cl("cta")}
        />
      </div>
    </article>,
    document.body
  );
}
