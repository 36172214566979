import styles from "./image_content_offset.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text, Container, CTA, Image, Wysiwyg } from "@scripts/widgets";
import useViewportContext from "@scripts/providers/ViewportProvider";
import useAppState from "@scripts/providers/AppProvider";

ImageContentOffset.propTypes = {
  title: PropTypes.string,
  largeImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  smallImage: PropTypes.shape({
    src: PropTypes.string,
    alt: PropTypes.string,
  }),
  subtitle: PropTypes.string,
  description: PropTypes.string,
  html: PropTypes.string,
  cta: PropTypes.shape({
    text: PropTypes.string,
    link: PropTypes.string,
  }),
  className: PropTypes.string,
};

export default function ImageContentOffset({
  title = "",
  description = "",
  subtitle,
  cta,
  largeImage,
  smallImage,
  className = "",
  html,
}) {
  const { accessible } = useAppState();
  const cl = accessible ? () => null : _classes(styles);

  const { mobileUp, mobileDown } = useViewportContext();
  const renderDesktop = () => (
    <Container className={cl("container")}>
      <div className={cl("left")}>
        <div className={cl("large_image")}>
          <Image src={largeImage?.src} alt={largeImage?.alt} />
        </div>
      </div>
      <div className={cl("right")}>
        <div className={cl("small_image")}>
          <Image src={smallImage?.src} alt={smallImage?.alt} />
        </div>
        <Text
          text={subtitle}
          component="h3"
          font="primary"
          className={cl("subtitle")}
        />
        {description && (
          <Text text={description} className={cl("description")} />
        )}
        {html && <Wysiwyg html={html} className={cl("description")} />}
        <CTA link={cta?.link} text={cta?.text} variant="secondary" />
      </div>
    </Container>
  );

  const renderMobile = () => (
    <Container className={cl("container")}>
      <div className={cl("images_mobile")}>
        <div className={cl("large_image")}>
          <Image src={largeImage?.src} alt={largeImage?.alt} />
        </div>
        <div className={cl("small_image")}>
          <Image src={smallImage?.src} alt={smallImage?.alt} />
        </div>
      </div>

      <Text
        text={subtitle}
        component="h3"
        font="primary"
        className={cl("subtitle")}
      />
      {description && <Text text={description} className={cl("description")} />}
      {html && <Wysiwyg html={html} className={cl("description")} />}
      <CTA link={cta?.link} text={cta?.text} variant="secondary" />
    </Container>
  );
  return (
    <section className={cl(["root", className])}>
      {mobileDown && renderMobile()}
      {mobileUp && renderDesktop()}
    </section>
  );
}
