import styles from "./text.scss";
import { _classes } from "@scripts/utilities/helpers";
import { IntlMessage } from "@scripts/localization";
import { useLocalization } from "@scripts/localization";

const cl = _classes(styles);

Text.propTypes = {
  component: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "p",
    "span",
    "label",
  ]),
  variant: PropTypes.oneOf([
    "h1",
    "h2",
    "h3",
    "h4",
    "h5",
    "h6",
    "body1",
    "body2",
    "body3",
    "body4",
    "mobileNavMain",
    "mobileNavChild",
  ]),
  className: PropTypes.string,
  text: PropTypes.string,
  weight: PropTypes.oneOf(["bold"]),
  defaultMessage: PropTypes.string,
  font: PropTypes.oneOf(["primary", "secondary"]),
  htmlFor: PropTypes.string,
  id: PropTypes.string,
};
export default function Text({
  children,
  component = "span",
  variant = "body1",
  className = "",
  text = "",
  weight,
  defaultMessage,
  font = "",
  htmlFor,
  id = "",
}) {
  const { rtlClass } = useLocalization();
  const classes = cl([
    "root",
    variant,
    weight || "",
    `font-${font}`,
    className,
    rtlClass,
  ]);

  const content = () => {
    if (children) {
      return children;
    }

    if (text) {
      return <IntlMessage id={id || text} defaultMessage={text} />;
    }

    return null;
  };

  return React.createElement(
    component,
    {
      className: classes,
      htmlFor,
    },
    content()
  );
}
