import dayjs from "dayjs";
import { createContext, useContext, useState, useEffect } from "react";
import QueryString from "query-string";
import {
  BOOKING_ENGINE_CHAIN_ID,
  BOOKING_ENGINE_HOTEL_ID,
} from "../../config/appConfig";
import { useLocalization } from "@scripts/localization";

const BookingWidgetContext = createContext({});

export function BookingWidgetProvider({ children }) {
  const { currentLocale, rtlClass } = useLocalization();
  const today = new Date();
  const tomorrow = new Date();
  const inTwoDays = new Date();
  tomorrow.setDate(today.getDate() + 1);
  inTwoDays.setDate(today.getDate() + 3);

  const [formState, setFormState] = useState({
    ad1: 1,
    ch1: 0,
    inf1: 0,
    ad2: 0,
    ch2: 0,
    inf2: 0,
    ad3: 0,
    ch3: 0,
    inf3: 0,
    rooms: 1,
    from: tomorrow,
    to: inTwoDays,
  });

  const roomsValue = formState["rooms"];

  const onSubmit = async (e) => {
    e.preventDefault();

    const {
      ad1,
      ch1,
      inf1 = 0,
      ad2,
      ch2,
      inf2,
      ad3,
      ch3,
      inf3,
      rooms,
      from,
      to,
    } = formState;
    const fromDate = dayjs(from).format("YYYY-MM-DD");
    const outDate = dayjs(to).format("YYYY-MM-DD");
    const params = QueryString.stringify(
      {
        lang: currentLocale.code == "en" ? "eng" : "heb",
        chainId: BOOKING_ENGINE_CHAIN_ID,
        hotel: BOOKING_ENGINE_HOTEL_ID,
        promo: undefined, // to come later?
        in: fromDate,
        out: outDate,
        rooms,
        ad1,
        ch1,
        inf1,
        ad2: ad2 ? ad2 : undefined,
        ch2: ad2 ? ch2 : undefined,
        inf2: ad2 ? 0 : undefined,
        ad3: ad3 ? ad3 : undefined,
        ch3: ad3 ? ch3 : undefined,
        inf3: ad3 ? 0 : undefined,
      },
      { addQueryPrefix: true }
    );

    window.open(`https://booking.simplex-ltd.com/be/rooms?${params}`);

    return true;
  };

  // update values as we add and remove rooms
  useEffect(() => {
    const ad2NextVal = roomsValue >= 2 ? formState["ad2"] || 1 : 0;
    const ch2NextVal = roomsValue >= 2 ? formState["ch2"] || 0 : 0;
    const ad3NextVal = roomsValue == 3 ? formState["ad3"] || 1 : 0;
    const ch3NextVal = roomsValue == 3 ? formState["ch3"] || 0 : 0;

    setFormState({
      ...formState,
      ad2: ad2NextVal,
      ch2: ch2NextVal,
      ad3: ad3NextVal,
      ch3: ch3NextVal,
      adults: Number(formState["ad1"]) + ad2NextVal + ad3NextVal,
    });
  }, [roomsValue]);

  // update adults aggregate value and adjust child values so they stay under the limit (2)
  useEffect(() => {
    setFormState({
      ...formState,
      adults: getNewSumValue([formState.ad1, formState.ad2, formState.ad3]),
      ch1: getFormattedChildValue(formState.ad1, formState.ch1),
      ch2: getFormattedChildValue(formState.ad2, formState.ch2),
      ch3: getFormattedChildValue(formState.ad3, formState.ch3),
    });
  }, [formState.ad1, formState.ad2, formState.ad3]);

  // update children aggregate value.
  useEffect(() => {
    setFormState({
      ...formState,
      children: getNewSumValue([formState.ch1, formState.ch2, formState.ch3]),
    });
  }, [formState.ch1, formState.ch2, formState.ch3]);

  function getFormattedChildValue(adultValue, childValue) {
    if (adultValue == 5) {
      return 0;
    }
    if (adultValue == 4 && childValue > 1) {
      return 1;
    }

    return childValue || 0;
  }

  function getNewSumValue(values = []) {
    return values.reduce((sum, val) => {
      if (val && val > 0) {
        return sum + val;
      }
      return sum;
    }, 0);
  }

  return (
    <BookingWidgetContext.Provider
      value={{
        formState,
        setValue: (name, value) => {
          return setFormState({ ...formState, [name]: value });
        },
        setFormState,
        onSubmit,
      }}
    >
      {children}
    </BookingWidgetContext.Provider>
  );
}

export default function useBookingWidgetContext() {
  return useContext(BookingWidgetContext);
}
