// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".card__root___dTXF0{display:block}.card__image___z7e_z{height:300px;width:300px}.card__details___QdqXT{padding:20px 0px;background:#fff}.card__details___QdqXT .card__title___mAa0q{color:#a2662f}.card__description___JLsuZ{padding:10px 0px 5px}.card__cta___JgB9j{margin-top:20px}", "",{"version":3,"sources":["webpack://./src/scripts/partials/cards/Card/card.scss","webpack://./src/styles/global/_colors.scss"],"names":[],"mappings":"AAEA,oBACE,aAAA,CAGF,qBACE,YAAA,CACA,WAAA,CAGF,uBACE,gBAAA,CACA,eCGM,CDDN,4CACE,aCfY,CDmBhB,2BACE,oBAAA,CAGF,mBACE,eAAA","sourcesContent":["@import \"@styles/global/_parts.scss\";\n\n.root {\n  display: block;\n}\n\n.image {\n  height: 300px;\n  width: 300px;\n}\n\n.details {\n  padding: 20px 0px;\n  background: $white;\n\n  .title {\n    color: $primary_brown;\n  }\n}\n\n.description {\n  padding: 10px 0px 5px;\n}\n\n.cta {\n  margin-top: 20px;\n}\n","//theme colors\n$primary_brown: #a2662f;\n$primary_black: #000;\n$primary_white: #fff;\n$secondary_green: #395437;\n$secondary_grey: #d1cbbc;\n$secondary_orange: #f8992e;\n$secondary_gold: #ffc709;\n$secondary_yellow: #fee000;\n\n//other found colors\n$black_100: #e8e5db;\n$black_300: #6b6b6b;\n$secondary_beige: #f3f1ed;\n$secondary_tan: #feebd5;\n\n$white: #fff;\n$black: $primary_black;\n$errorRed: #e01c1c;\n$blue: #4d90fe;\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "card__root___dTXF0",
	"image": "card__image___z7e_z",
	"details": "card__details___QdqXT",
	"title": "card__title___mAa0q",
	"description": "card__description___JLsuZ",
	"cta": "card__cta___JgB9j"
};
export default ___CSS_LOADER_EXPORT___;
