import styles from "./rooms_divider.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text, Container } from "@scripts/widgets";

const cl = _classes(styles);

RoomsDivider.propTypes = {
  title: PropTypes.string,
  className: PropTypes.string,
};

export default function RoomsDivider({ title = "", className = "" }) {
  return (
    <section className={cl(["root", className])}>
      <Container>
        <Text text={title} component="h3" font="secondary" />
        <div className={cl("divider")} />
      </Container>
    </section>
  );
}
