import styles from "./container.scss";
import { _classes } from "@scripts/utilities/helpers";
import useAppState from "@scripts/providers/AppProvider";

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
};

export default function Container({ children, className = "" }) {
  const { accessible } = useAppState();
  const cl = _classes(styles);

  return <div className={cl(["root", className])}>{children}</div>;
}
