import styles from "./intro_section.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text } from "@scripts/widgets";
import useAppState from "@scripts/providers/AppProvider";

IntroText.propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
};

export default function IntroText({
  text = "",
  className = "",
  component = "h1",
}) {
  const { accessible } = useAppState();
  const cl = accessible ? () => null : _classes(styles);

  return (
    <section className={cl(["root", className])}>
      <div className={cl("container")}>
        <Text
          text={text}
          component={component}
          variant="h2"
          className={cl("text")}
        />
      </div>
    </section>
  );
}
