import styles from "./card_list.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text } from "@scripts/widgets";
import Card from "../Card/Card";
import { useEffect, useRef } from "react";
import useAppState from "../../../providers/AppProvider";

const cl = _classes(styles);

CardList.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  cards: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.shape({
        src: PropTypes.string,
        alt: PropTypes.string,
      }),
      cta: PropTypes.shape({
        text: PropTypes.string,
        link: PropTypes.string,
      }),
    })
  ),
  count: PropTypes.oneOf(["three", "four"]),
};

export default function CardList({
  title,
  cards = [],
  className = "",
  count = "four",
}) {
  const { accessible } = useAppState();

  const listRef = useRef(null);

  const posRef = useRef({ top: 0, left: 0, x: 0, y: 0 });

  const mouseDownHandler = function (e) {
    listRef.current.style.cursor = "grabbing";
    listRef.current.style.userSelect = "none";

    posRef.current = {
      left: listRef.current.scrollLeft,
      top: listRef.current.scrollTop,
      x: e.clientX,
      y: e.clientY,
    };

    document.addEventListener("mousemove", mouseMoveHandler);
    document.addEventListener("mouseup", mouseUpHandler);
  };

  const mouseMoveHandler = function (e) {
    const dx = e.clientX - posRef.current.x;
    const dy = e.clientY - posRef.current.y;

    listRef.current.scrollTop = posRef.current.top - dy;
    listRef.current.scrollLeft = posRef.current.left - dx;
  };

  const mouseUpHandler = function () {
    document.removeEventListener("mousemove", mouseMoveHandler);
    document.removeEventListener("mouseup", mouseUpHandler);

    listRef.current.style.cursor = "grab";
    listRef.current.style.removeProperty("user-select");
  };

  useEffect(() => {
    listRef.current.addEventListener("mousedown", mouseDownHandler);

    return () =>
      listRef.current.removeEventListener("mousedown", mouseDownHandler);
  }, []);

  return (
    <section className={cl(["root", className])}>
      <div className={cl("container")}>
        <Text
          text={title}
          varian="h5"
          component="h2"
          font="secondary"
          className={cl("title")}
        />
        <ul className={cl("list")} ref={listRef}>
          {cards?.map((props, index) => (
            <li key={index} className={cl(["item", count])}>
              <Card
                {...props}
                imageClassName={cl([
                  "image",
                  count,
                  accessible ? "accessible" : "",
                ])}
              />
            </li>
          ))}
        </ul>
      </div>
    </section>
  );
}
