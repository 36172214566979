import { useEffect, useRef, useState } from "react";
import styles from "./video.scss";
import { _classes } from "@scripts/utilities/helpers";

const cl = _classes(styles);

VideoPlayer.propTypes = {
  src: PropTypes.string,
  playing: PropTypes.bool,
  controls: PropTypes.bool,
  volume: PropTypes.number, //between 0 and 1
  loop: PropTypes.bool,
  muted: PropTypes.bool,
  backgroundImage: PropTypes.string,
  playsinline: PropTypes.bool,
  playIcon: PropTypes.func,
  autoplay: PropTypes.bool,
  containerClassName: PropTypes.string,
  containerStyles: PropTypes.object,
  styles: PropTypes.object,
  width: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
  height: PropTypes.oneOfType(PropTypes.number, PropTypes.string),
};

VideoPlayer.defaultProps = {
  src: "",
  loop: false,
  muted: false,
  controls: true,
  volume: 0.05,
  width: 1600,
  height: 900,
  containerClassName: "",
  containerStyles: {
    width: "100%",
    position: "relative",
    paddingTop: `${(900 / 1600) * 100}%`,
  },
  styles: {
    width: "100%",
    height: "100%",
    position: "absolute",
    left: 0,
    top: 0,
  },
};
const BASEBUTTON = (props) => <button {...props} />;
function VideoPlayer(props) {
  const [playing, setIsPlaying] = useState(false);
  //get video tag ref
  let videoRef = useRef(null);
  ///on mount we can change all attributes
  const PLAYICON = props.playIcon ? props.playIcon : BASEBUTTON;
  useEffect(() => {
    const video = videoRef.current;
    videoDefault();
    if (props.autoplay) {
      video.autoplay = true;
      video.volume = 0;
      video.oncanplay = () => {
        setIsPlaying(true);
        video.play();
      };
    }
    fixVideoControls();
  }, []);

  const pauseStart = () => {
    const video = videoRef.current;
    if (playing) {
      video.onpause = () => {
        setIsPlaying(false);
        video.pause();
      };
    }
    if (!playing) {
      video.onplay = () => {
        setIsPlaying(true);
        video.play();
      };
    }
  };
  const videoDefault = () => {
    const video = videoRef.current;
    video.volume = props.volume;
  };
  const togglePlay = () => {
    const video = videoRef.current;
    if (!playing) {
      setIsPlaying(true);
      video.play();
    } else {
      setIsPlaying(false);
      video.pause();
    }
  };
  ///hide download cta from video
  const fixVideoControls = () => {
    const video = videoRef.current;
    video.setAttribute("controlsList", "nodownload");
    video.setAttribute("disablePictureInPicture", true);
  };
  const setratio =
    typeof props.width !== "string" && typeof props.height !== "string";
  const containerBase = {
    ...props.containerStyles,
    paddingTop: setratio
      ? `${(props.height / props.width) * 100}%`
      : prop.height,
  };
  return (
    <div className={props.containerClassName} style={containerBase}>
      <video
        style={props.styles}
        ref={videoRef}
        src={props.src}
        controls={props.controls}
        loop={props.loop}
        playsinline
        poster={props.backgroundImage}
        onClick={pauseStart}
      />
      <div>
        <PLAYICON
          className={cl(["video__play_icon", playing && "hidden"])}
          onClick={togglePlay}
        />
      </div>
    </div>
  );
}

export default VideoPlayer;
