// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".video__video__play_icon___IEsNN{position:absolute;left:50%;top:50%;transform:translate(-50%, -50%);width:100px;height:100px;background-color:rgba(255,255,255,.5);border-radius:100%;padding:0;cursor:pointer;transition:opacity 300ms;-webkit-backface-visibility:hidden;backface-visibility:hidden}.video__video__play_icon___IEsNN:after{content:\"\";display:block;position:absolute;left:55%;top:50%;width:0;height:0;border-top:25px solid rgba(0,0,0,0);border-left:35px solid #fff;border-bottom:25px solid rgba(0,0,0,0);transform:translate(-50%, -50%)}.video__video__play_icon___IEsNN.video__hidden___ikIH4{opacity:0}video[poster]{-o-object-fit:fill;object-fit:fill}", "",{"version":3,"sources":["webpack://./src/scripts/widgets/VideoPlayer/video.scss"],"names":[],"mappings":"AAEA,iCACE,iBAAA,CACA,QAAA,CACA,OAAA,CACA,+BAAA,CACA,WAAA,CACA,YAAA,CACA,qCAAA,CACA,kBAAA,CACA,SAAA,CACA,cAAA,CACA,wBAAA,CACA,kCAAA,CAAA,0BAAA,CACA,uCACE,UAAA,CACA,aAAA,CACA,iBAAA,CACA,QAAA,CACA,OAAA,CACA,OAAA,CACA,QAAA,CACA,mCAAA,CACA,2BAAA,CACA,sCAAA,CACA,+BAAA,CAEF,uDACE,SAAA,CAIJ,cACE,kBAAA,CAAA,eAAA","sourcesContent":["@import \"@styles/global/_parts.scss\";\n\n.video__play_icon {\n  position: absolute;\n  left: 50%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  width: 100px;\n  height: 100px;\n  background-color: rgba($color: #ffffff, $alpha: 0.5);\n  border-radius: 100%;\n  padding: 0;\n  cursor: pointer;\n  transition: opacity 300ms;\n  backface-visibility: hidden;\n  &:after {\n    content: \"\";\n    display: block;\n    position: absolute;\n    left: 55%;\n    top: 50%;\n    width: 0;\n    height: 0;\n    border-top: 25px solid transparent;\n    border-left: 35px solid white;\n    border-bottom: 25px solid transparent;\n    transform: translate(-50%, -50%);\n  }\n  &.hidden {\n    opacity: 0;\n  }\n}\n\nvideo[poster] {\n  object-fit: fill;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"video__play_icon": "video__video__play_icon___IEsNN",
	"hidden": "video__hidden___ikIH4"
};
export default ___CSS_LOADER_EXPORT___;
