import React, { useReducer, useEffect, useState } from "react";
import styles from "./script_booking_widget.scss";
import { _classes } from "@scripts/utilities/helpers";

const cl = _classes(styles);

const ScriptBookingWidget = ({ currentLocale }) => {
  const [localeLanguage, setLocaleLanguage] = useState(currentLocale.code);
  useEffect(() => {}, []);
  return (
    <div id="booking-engine-beproc">
      <div
        id="booking-engine-holder"
        data-params={`?chainid=143&lang=${
          localeLanguage === "he" ? "heb" : "eng"
        }`}
        data-init="engine"
        data-apiprefixurl="https://booking.simplex-ltd.com/BE_EngineService"
        className={cl(["relative", "main-engine"])}
      ></div>
    </div>
  );
};

export default ScriptBookingWidget;
