import { Text, Link } from "@scripts/widgets";
import styles from "./cta.scss";
import { _classes, parse } from "@scripts/utilities/helpers";
import { useLocalization } from "@scripts/localization";
import useAppState from "@scripts/providers/AppProvider";

const cl = _classes(styles);

CTA.propTypes = {
  variant: PropTypes.oneOf([
    "primary",
    "secondary",
    "cookie",
    "outline_transparent",
  ]),
  size: PropTypes.oneOf(["small", "medium", "large"]),
  fullWidth: PropTypes.bool,
  text: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  link: PropTypes.string,
  external: PropTypes.bool,
  buttonlink: PropTypes.string,
  submit: PropTypes.bool,
  buttonOnly: PropTypes.bool,
};

export default function CTA({
  variant = "primary",
  size = "medium",
  text,
  disabled = false,
  onClick,
  className = "",
  fullWidth,
  link,
  external,
  buttonlink,
  submit,
  buttonOnly,
  isHebrewBookingCta,
}) {
  const { accessible } = useAppState();
  const { rtlClass } = useLocalization();
  let classes = [
    "inner",
    styles[variant] || styles.primary,
    styles[size] || styles.medium,
    fullWidth ? "full_width" : "",
    isHebrewBookingCta && "hebrew_booking_cta",
  ];

  if (accessible) {
    classes = ["inner", styles.accessible, styles.medium];
  }

  let formatted = { link };

  if (buttonlink) {
    const parsed = parse(buttonlink, {});
    formatted.link = parsed.link ? parsed.link : link;
    formatted.text = parsed.text;
  }

  formatted.text = text || formatted.text;

  if (submit) {
    return (
      <div className={cl(["root", className, rtlClass])}>
        <button
          aria-label={"submit"}
          className={cl(classes)}
          type={buttonOnly ? "button" : "submit"}
          onClick={onClick}
        >
          <Text text={formatted.text} className={cl("text")} />
        </button>
      </div>
    );
  }

  if (!formatted.link && !onClick) {
    return null;
  }

  return (
    <div className={cl(["root", className, rtlClass])}>
      <Link
        path={formatted.link}
        onClick={onClick}
        className={cl(classes)}
        external={external}
        text={text}
      >
        <Text text={formatted.text} className={cl("text")} />
      </Link>
    </div>
  );
}
