import { Controller } from "react-hook-form/dist/index.ie11";
import { Label, ErrorMessage, useFormContext } from "@scripts/form";
import { v4 as uuidv4 } from "uuid";
import styles from "./textarea_input.scss";
import { _classes } from "@scripts/utilities/helpers";
import { useState } from "react";

const cl = _classes(styles);

const UUID = uuidv4();

TextareaInput.propTypes = {
  name: PropTypes.string,
  placeholder: PropTypes.string,
  type: PropTypes.string,
  label: PropTypes.string,
  rules: PropTypes.object,
  defaultValue: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  variant: PropTypes.string,
};

TextareaInput.defaultProps = {
  name: "input",
  label: "",
  placeholder: "",
  type: "text",
  defaultValue: "",
  className: "",
  variant: "dark",
};

export default function TextareaInput({
  name,
  placeholder,
  type,
  label,
  rules,
  className = "",
  defaultValue,
  disabled,
  variant,
}) {
  const { control, errors } = useFormContext();
  const id = `${name}__${UUID}`;

  const [focused, setFocus] = useState(false);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({ onChange, value = "" }) => (
        <div
          className={cl(["root", (focused || value) && "focus", className])}
          style={{ display: type === "hidden" ? "none" : "block" }}
        >
          <Label
            value={label}
            htmlFor={id}
            variant={variant}
            className={cl("label")}
          />
          <div className={cl("control")}>
            <textarea
              id={id}
              disabled={disabled}
              onChange={onChange}
              name={name}
              value={value}
              placeholder={placeholder}
              className={cl(["textarea", variant])}
              onFocus={() => setFocus(true)}
              onBlur={() => setFocus(false)}
            ></textarea>
          </div>
          <ErrorMessage error={errors[name]} />
        </div>
      )}
    />
  );
}
