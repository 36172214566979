import styles from "./amenities_services_popup.scss";
import { _classes } from "@scripts/utilities/helpers";
import { Text, Modal, Wysiwyg } from "@scripts/widgets";
import { useLocalization } from "@scripts/localization";

const cl = _classes(styles);

AmenitiesServicesPopup.propTypes = {
  text1: PropTypes.string,
  text2: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default function AmenitiesServicesPopup({
  text1,
  text2,
  isOpen,
  setIsOpen,
}) {
  const { rtlClass } = useLocalization();

  return (
    <Modal
      open={isOpen}
      onClose={setIsOpen}
      aria-label="promo popup"
      modalClassname={cl("root")}
    >
      <div className={cl("content")}>
        <Text
          text={"rooms.inRoomAmenities"}
          variant={"h4"}
          component={"h4"}
          className={cl(["title", rtlClass])}
          weight="bold"
        />

        <Wysiwyg html={text1} className={cl(["accordion_content", rtlClass])} />

        <Text
          text={"rooms.services"}
          variant={"h4"}
          component={"h4"}
          className={cl(["title", rtlClass])}
          weight="bold"
        />

        <Wysiwyg html={text2} className={cl(["accordion_content", rtlClass])} />
      </div>
    </Modal>
  );
}
